import { createUseStyles, Styles } from '@pff-consumer/tweed-ui/web-styles'

interface HeaderStyles {
  header: Styles
  logo: Styles
  loginButton: Styles
}

export const useStyles = createUseStyles<HeaderStyles>((theme): HeaderStyles => {
  return {
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      height: theme.calcUnit(64),
      backgroundColor: theme.colors.textColor,
      ...theme.breakpoint.down('lg', {
        position: 'sticky',
        zIndex: '1',
        width: '100%',
        top: theme.calcUnit(0),
      }),
    },
    logo: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    loginButton: {
      fontFamily: 'Roboto',
      fontWeight: '600',
      fontSize: theme.calcUnit(16),
      lineHeight: theme.calcUnit(20),
      color: theme.colors.webInverseTextColor,
      backgroundColor: theme.colors.inverseBackgroundColor,
      border: `${theme.calcUnit(1)} solid ${theme.colors.primaryColor}`,
      borderRadius: theme.calcUnit(theme.borderRadius.roundedDefault),
      cursor: 'pointer',

      height: theme.calcUnit(36),
      padding: `${theme.calcUnit(theme.spacing.s2)} ${theme.calcUnit(theme.spacing.s3)}`,
      marginRight: theme.calcUnit(theme.spacing.s5),
    },
  }
})
