import { createUseStyles, Styles } from '@pff-consumer/tweed-ui/web-styles'

interface LeftPaneStyles {
  leftPaneContainer: Styles
  backgroundImage: Styles
  foregroundImageContainer: Styles
  foregroundImage: Styles
  gradientLayer: Styles
  contentContainer: Styles
  contentDescription: Styles
  carouselContainer: Styles
  insightsContainer: Styles
  draftSticker: Styles
}

/**
 * The left pane background image is constructed as a three layered item with background, gradient and foreground
 * 1) The bottom most layer is the background image
 * 2) Gradient layer sits on top of the background image which has a z-index of 10
 * 3) Foreground image with symbols sits on top of the gradient layer with a z-index of 20
 * Content is the top most layer which sits on top of foreground image with a z-index of 30
 */

export const useStyles = createUseStyles<LeftPaneStyles>((theme) => {
  const carouselHeight = 124
  return {
    leftPaneContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
      overflowY: 'scroll',
      backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 3.36%, ${theme.colors.inverseBackgroundColor} 103.36%), url("/assets/images/create-account-background.webp")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    backgroundImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    gradientLayer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      background: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 3.36%, ${theme.colors.inverseBackgroundColor} 103.36%)`,
      opacity: '100%',
      zIndex: 10,
    },
    foregroundImageContainer: {
      width: '100%',
      height: '60%',
      position: 'relative',
    },
    foregroundImage: {
      position: 'relative',
      top: 0,
      display: 'inline',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'normal',
      zIndex: 30,
      objectFit: 'contain',
      borderRadius: theme.calcUnit(theme.borderRadius.roundedDefault),
    },
    contentContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      padding: theme.calcUnit(32),
      zIndex: 31,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    contentDescription: {
      fontFamily: 'Roboto',
      fontSize: theme.calcUnit(16),
      fontWeight: 400,
      lineHeight: theme.calcUnit(24),
      textAlign: 'left',
      color: theme.colors.webInverseTextColor,
      marginBottom: theme.calcUnit(12 + carouselHeight),
      // @ts-expect-error
      ...theme.breakpoint.up('xl', {
        fontSize: theme.calcUnit(16),
        lineHeight: theme.calcUnit(32),
      }),
    },
    carouselContainer: {
      position: 'absolute',
      bottom: theme.calcUnit(44),
      left: 0,
      height: theme.calcUnit(carouselHeight),
      width: '100%',
    },
    insightsContainer: {
      display: 'flex',
      alignSelf: 'center',
      width: '95%',
      padding: theme.calcUnit(theme.spacing.s5),
      paddingTop: 0,
      borderRadius: theme.calcUnit(theme.borderRadius.roundedDefault),
      marginBottom: theme.calcUnit(theme.spacing.s5),
      marginTop: theme.calcUnit(theme.spacing.s6),
    },
    draftSticker: {
      position: 'absolute',
      bottom: `-${theme.calcUnit(theme.spacing.s4)}`,
      right: `${theme.calcUnit(theme.spacing.s3)}`,
      zIndex: theme.zIndex.highestLevel,
    },
  }
})
