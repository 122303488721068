import React from 'react'
import { colors } from '@pff-consumer/core-ui'
import { useBreakpoints } from '@pff-consumer/web-ui/hooks/use-dimensions'
import { Icon } from '@pff-consumer/web-ui/components/icon/icon'
import { SVGGroup } from '@pff-consumer/utils'
import { Text, TextDescriptionVariant } from '@pff-consumer/tweed-ui/atoms/browser-text'
import { useStyles } from './pff-insights.styles'
import { Separator } from '../separator/separator'

const insightBulletPoints: string[] = [
  'PFF Grades & Premium Stats',
  'Premier Analysis',
  'In-Season Fantasy Tools',
  'Mock Draft Simulator',
]

const insightMessages: string[] = [
  'Unlock exclusive data for every player and every game from the college to pros.',
  'Gain unlimited access to expert insights and premium content, spanning fantasy, betting, and so much more.',
  'Tap into PFF’s award-winning rankings, Start/Sit Analyzer, positional matchups, and more to help push to the playoffs.',
  'Shape your favorite team with the industry’s best NFL Draft Tool.',
]

export const PffInsights = () => {
  const { isDesktop } = useBreakpoints()
  const styles = useStyles()

  return (
    <div className={styles.messagesContainer}>
      <Text
        variant={
          !isDesktop ? TextDescriptionVariant.LARGE_SECONDARY_BOLD : TextDescriptionVariant.XJUMBO_SECONDARY_BOLD
        }
        colorOverride={!isDesktop ? colors.webTextColorDefault : colors.webInverseTextColor}
      >
        POWER YOUR GAME WITH PFF+
      </Text>
      {!isDesktop && (
        <div className={styles.separator}>
          <Separator
            borderWidth={1}
            borderColor={colors.webBorderSeparator}
          />
        </div>
      )}
      <div className={styles.subHeader}>
        <Text
          variant={
            !isDesktop ? TextDescriptionVariant.MEDIUM_PRIMARY_REGULAR : TextDescriptionVariant.LARGE_PRIMARY_REGULAR
          }
          colorOverride={!isDesktop ? colors.webTextColorDefault : colors.webInverseTextColor}
        >
          Make the winning decision every time with tools and data trusted by all 32 NFL teams.
        </Text>
      </div>

      {insightBulletPoints.map((message, index) => (
        <div
          key={message}
          className={styles.insightsContainer}
        >
          <Icon
            className={styles.icon}
            group={SVGGroup.Icons}
            name='checkmark-green'
            testID='feature-point-icon'
            width={20}
            height={16}
          />
          <div className={styles.insightsPoints}>
            <span>
              <Text
                variant={TextDescriptionVariant.MEDIUM_PRIMARY_BOLD}
                colorOverride={!isDesktop ? colors.webTextColorDefault : colors.webInverseTextColor}
              >
                {message}:
              </Text>
            </span>
            <span className={styles.insightDescription}>
              <Text
                variant={TextDescriptionVariant.MEDIUM_PRIMARY_REGULAR}
                colorOverride={!isDesktop ? colors.webTextColorDefault : colors.webInverseTextColor}
              >
                {insightMessages[index]}
              </Text>
            </span>
          </div>
        </div>
      ))}
    </div>
  )
}
